import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"

import Axios from 'axios'
import isEmail from 'validator/lib/isEmail';

import Tooltip from '../../components/tooltip'

const minAnswerLength = 3
const requiredFields = ["name", "company", "email", "message"]

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formLabel: {
    marginTop: 15,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHidden: {
    marginLeft: -3000,
  },
  textFieldMulti: {
    height: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
})

class ContactForm extends React.Component {
  state = {
    name: "",
    company: "",
    email: "",
    phone: "",
    website: "",
    enquiryType: "rail",
    licenseHolder: "no",
    becomeLicenseHolder: "no",
    currentRetailer: "no",
    railOnly: "yes",
    splitTicketing: "no",
    seatSelection: "no",
    mobileTickets: "no",
    message: "",

    nameError: false,
    companyError: false,
    emailError: false,
    messageError: false,
    formError: false,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleChangeCheckbox = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  sendForm = async () => {

    try
    {
      let commentField = document.getElementById('comment')

      if (commentField.value === "") // Otherwise a bot has completed the form!
      {
        let data = {...this.state}
        delete data.nameError
        delete data.companyError
        delete data.emailError
        delete data.messageError
        delete data.formError

        let options = {
          method : 'POST',
          url : 'https://api.hazardousfrog.com/admin-api/sendContactEmail',
          data : data,
          headers : {
            'Content-type': 'application/json',
          }
        }

        let result = await Axios(options)

        console.log("send result",result)

        return true
      }
    }
    catch(err)
    {
      console.error(err)
      return false
    }
  }

  submitHandler = async event => {
    let formError = false

    for (let f = 0; f < requiredFields.length; f++) {
      let field = requiredFields[f]
      let errorVariable = field + "Error"
      let isError = this.state[field].length < minAnswerLength

      this.setState({ [errorVariable]: isError })

      formError = formError || isError
    }

    // Also check that email is valid...
    if (!isEmail(this.state.email))
    {
      this.setState({emailError:true})
      formError = true
    }

    this.setState({ formError })

    if (!formError) {
      let sentOK = await this.sendForm()

      if (sentOK)
      {
        this.props.showConfirmation()
      }
    }
  }

  render() {
    const { classes } = this.props

    return (
      <form className={this.props.className} autoComplete="off">
        <FormControl component="fieldset" className={classes.formControl}>
          <TextField
            id="name"
            label="Name"
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange("name")}
            margin="normal"
            required={true}
            error={this.state.nameError}
          />
          <TextField
            id="company"
            label="Company"
            className={classes.textField}
            value={this.state.company}
            onChange={this.handleChange("company")}
            margin="normal"
            required={true}
            error={this.state.companyError}
          />
          <TextField
            id="email"
            label="Email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange("email")}
            margin="normal"
            required={true}
            error={this.state.emailError}
          />
          <TextField
            id="phone"
            label="Phone"
            className={classes.textField}
            value={this.state.prone}
            onChange={this.handleChange("phone")}
            margin="normal"
          />
          <TextField
            id="website"
            label="Website"
            className={classes.textField}
            value={this.state.website}
            onChange={this.handleChange("website")}
            margin="normal"
          />
        </FormControl>

        <div className="rail">
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.formLabel}>
              What are you enquiring about?
            </FormLabel>
            <RadioGroup
              row
              name="enquiryType"
              value={this.state.enquiryType}
              onChange={this.handleChange("enquiryType")}
            >
              <FormControlLabel
                value="rail"
                control={<Radio />}
                label="UK Rail API"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>

            <TextField
              id="message"
              label="Message"
              className={classes.textFieldMulti}
              value={this.state.message}
              onChange={this.handleChange("message")}
              margin="normal"
              multiline
              rows={8}
              required={true}
              error={this.state.messageError}
            />
          </FormControl>
        </div>

        {this.state.enquiryType === "rail" && (
          <React.Fragment>
            <div>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Are you an <Tooltip>RDG</Tooltip> license holder?
                </FormLabel>
                <RadioGroup
                  row
                  name="licenseHolder"
                  value={this.state.licenseHolder}
                  onChange={this.handleChange("licenseHolder")}
                >
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>

                {this.state.licenseHolder === "no" && (
                  <React.Fragment>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Are considering becoming an <Tooltip>RDG</Tooltip> license holder?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="licenseHolder"
                      value={this.state.becomeLicenseHolder}
                      onChange={this.handleChange("becomeLicenseHolder")}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>

                    <FormLabel component="legend" className={classes.formLabel}>
                      Do you currently retail UK rail products?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="licenseHolder"
                      value={this.state.currentRetailer}
                      onChange={this.handleChange("currentRetailer")}
                    >
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </React.Fragment>
                )}

                <FormLabel component="legend" className={classes.formLabel}>
                  How are you planning to retail UK rail?
                </FormLabel>
                <RadioGroup
                  row
                  name="licenseHolder"
                  value={this.state.railOnly}
                  onChange={this.handleChange("railOnly")}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Rail only"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="Integrated with other products"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.formLabel}>
                  I am especially interested in :
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.splitTicketing}
                        onChange={this.handleChangeCheckbox("splitTicketing")}
                        value="yes"
                      />
                    }
                    label="Split ticketing"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.seatSelection}
                        onChange={this.handleChangeCheckbox("seatSelection")}
                        value="yes"
                      />
                    }
                    label="Advanced seat selection"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.mobileTickets}
                        onChange={this.handleChangeCheckbox("mobileTickets")}
                        value="yes"
                      />
                    }
                    label="Mobile tickets"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </React.Fragment>
        )}

        <div>
          <TextField
            id="comment"
            label="Comment"
            className={classes.textFieldHidden}
          />

          {this.state.formError && (
            <p className="errorMsg">
              Please check above. Required information has not been provided.
            </p>
          )}
        </div>
        <div className="submit">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.submitHandler}
          >Send
          </Button>
        </div>
      </form>
    )
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactForm)
