import React from "react"

import Layout from "../../components/layout"
import ContactForm from "./form"

import "./index.less"

class Page extends React.Component {

  state = {
    showConfirmation: false,
  }

  showConfirmation() {
    this.setState({showConfirmation:true})
  }

  renderForm() {
    return (
      <content>
        <h1>Make an Enquiry</h1>
        <ContactForm className="ContactForm" showConfirmation={this.showConfirmation.bind(this)}/>
      </content>
    )
  }

  renderConfirmation() {

    return (
      <content>
        <h1>We will be in touch</h1>
        <p>Thank you for making an enquiry. We will be in touch shortly.</p>
      </content>
    )
  }

  render() {

    return (
      <Layout title="Contact us" keywords={[`rail api`, `train ticket`, `api`]}>
        <content>
          { this.state.showConfirmation ? this.renderConfirmation() : this.renderForm() }
        </content>
      </Layout>
    )
  }
}

export default Page
